<template>
  <div>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="handle-modal"
        v-model="modalHandleShow"
        centered
        size="lg"
        title="Plano Dental"
        @hidden="resetForm"
      >

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <b-form class="pl-2 pr-2 pt-2">
            <b-row>

              <!-- Field: Code -->
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Código"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Código"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="dentalPlan.code"
                      autofocus
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-col>

              <!-- Field: Procedure -->
              <b-col
                cols="12"
                md="10"
              >
                <validation-provider
                  #default="validationContext"
                  name="Nome"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="dentalPlan.name"
                      :state="getValidationState(validationContext) ? null : false"
                      :disabled="dentalPlan.name === 'Particular'"
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Tabela de Preços"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Tabela de Preços"
                    label-for="price-list-id"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="dentalPlan.price_list_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="false"
                      :options="priceLists"
                      placeholder="Selecione"
                      input-id="price-list-id"
                    >
                      <template #no-options="{}">
                        Nenhum resultado encontrado.
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Plano de Contas"
                  label-for="account-plan-id"
                >
                  <v-select
                    id="account-plan-id"
                    v-model="dentalPlan.account_plan_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                    :options="accountPlans"
                    placeholder="Selecione"
                  >
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>

                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Convênios"
                  label-for="health-insurances-id"
                >
                  <v-select
                    id="health-insurances-id"
                    v-model="dentalPlan.healthInsurances"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                    :options="healthInsurances"
                    placeholder="Selecione"
                    multiple
                  >
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>

                </b-form-group>
              </b-col>

            </b-row>
          </b-form>

        </b-card>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="$emit('save', dentalPlan); modalHandleShow = false"
          >
            Salvar
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </template>

      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BCard, BForm, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import priceListStoreModule from '@/views/apps/price-list/priceListStoreModule'
import accountPlanStoreModule from '@/views/apps/finances/account-plans/useStoreAccountPlans'
import useStoreHealthInsurances from '@/views/apps/health-insurances/useStoreHealthInsurances'

import store from '@/store'
import useDentalPlan from '../useDentalPlan'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  setup() {
    localize('pt_BR') // vee-validate messages

    const USER_APP_STORE_MODULE_NAME = 'store-price-list'
    const USER_APP_STORE_MODULE2_NAME = 'store-account-plan'
    const USER_APP_STORE_MODULE3_NAME = 'store-health-insurance'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, priceListStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, accountPlanStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.registerModule(USER_APP_STORE_MODULE3_NAME, useStoreHealthInsurances)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.unregisterModule(USER_APP_STORE_MODULE3_NAME)
    })

    store.dispatch('store-price-list/fetch')
    store.dispatch('store-account-plan/fetch')
    store.dispatch('store-health-insurance/fetch')

    const priceLists = computed(() => store.getters['store-price-list/forSelect'])
    const accountPlans = computed(() => store.getters['store-account-plan/forSelect']('1 - RECEITAS'))
    const healthInsurances = computed(() => store.getters['store-health-insurance/forSelect'])

    const modalHandleShow = ref(false)

    const blankDentalPlan = {
      id: null,
      code: null,
      name: null,
      price_list_id: null,
      healthInsurances: [],
      account_plan_id: null,
    }
    const dentalPlan = ref(JSON.parse(JSON.stringify(blankDentalPlan)))
    const resetFormData = () => {
      dentalPlan.value = JSON.parse(JSON.stringify(blankDentalPlan))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const fillData = data => {
      dentalPlan.value = data
    }

    const {
      refetchData,
      deleteData,
      hidden,
      modalAdjustPricesShow,

    } = useDentalPlan()

    return {
      required,
      modalHandleShow,
      modalAdjustPricesShow,
      hidden,

      refetchData,
      deleteData,
      getValidationState,
      resetForm,
      refFormObserver,
      priceLists,
      accountPlans,
      fillData,
      dentalPlan,
      healthInsurances,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .selectAllTdClass {
    width: 50px;
  }
  .codeTdClass {
    width: 206px;
  }
  .unitTdClass {
    width: 140px;
  }
  .priceTdClass {
    width: 206px;
  }
</style>
